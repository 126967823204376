import { useEffect, useMemo, useState } from 'react';

// The hook support scroll to the end of the horizontal element
export const useScrollToEndElement = (idElement: string) => {
  const [enableIconLeft, setEnableIconLeft] = useState(false);
  const [scrollList, setScrollList] = useState<HTMLElement | null>(null);
  const isScrollable = useMemo(
    () => scrollList?.clientWidth !== scrollList?.scrollWidth,
    [scrollList],
  );

  const handleScrollToEnd = (toRight: boolean) => {
    if (!scrollList) {
      return;
    }

    const scrollTo = toRight ? +scrollList.scrollWidth : -scrollList.scrollWidth;

    setEnableIconLeft(toRight);
    scrollList.scrollBy({
      top: 0,
      left: scrollTo,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const scrollList = document.getElementById(idElement);

    setScrollList(scrollList);
  }, [idElement]);

  return { enableIconLeft, isScrollable, handleScrollToEnd };
};

export const handleScrollToActiveElement = (parentClass: string, activeClass: string) => {
  const parentElement = document.getElementById(parentClass);

  if (!parentElement) {
    return;
  }

  const itemActive = parentElement.getElementsByClassName(activeClass)[0] as HTMLElement;

  if (!itemActive) {
    return;
  }

  const windowHorizontalCenter = window.innerWidth / 2;
  const elementHorizontalCenter = itemActive.clientWidth / 2;

  parentElement.scroll({
    left: itemActive.offsetLeft - windowHorizontalCenter + elementHorizontalCenter,
    behavior: 'smooth',
  });
};
